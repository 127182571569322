import { useTranslation } from 'next-i18next'
import styles from './ProducerContact.module.scss'
import SplitSection from 'components/split-section/SplitSection'
import parse from 'html-react-parser'
import { Button } from '@boltenergy-be/design-system'
import FaqSection from 'components/faq/faq-section/FaqSection'
import classNames from 'classnames'
import { routes } from 'utils/routes'
import { useRouter } from 'next/router'
import { ProducerContactProps } from 'features/producer-contact/types'
import { Info } from 'features/registration/assets'
import ProducerCard from 'components/producers/producer-card/ProducerCard'
import { QUOTES } from 'features/producer-contact/constants'
import { Language } from 'types/language'
import Head from 'next/head'
import Hero from 'components/sections/hero/Hero'
import Img from 'components/img/Img'
import { Heading } from '@boltenergy-be/design-system'
import SliderSection from 'components/slider-section/SliderSection'
import Link from 'components/link/Link'

const ProducerContact = ({ producers, questions }: ProducerContactProps) => {
  // i18n
  const { t } = useTranslation(['generator', 'common'])

  // Router
  const router = useRouter()

  return (
    <>
      {/* SEO */}
      <Head>
        <link rel="canonical" href={`https://www.boltenergie.be/${router.locale}${router.asPath}`} />
      </Head>

      <main className={styles['producer-contact']}>
        {/* Hero */}
        <Hero image={{ alt: 'hero video thumbnail', customClass: styles['hero-img'], publicId: 'website/producers/yannick-ronny' }}>
          <Hero.Title value={parse(t('hero.title', 'Word energie-opwekker bij Bolt'))} />
          <Hero.Paragraph
            value={t(
              'hero.description',
              'Produceer jij hernieuwbare stroom op grote schaal en wil je deze graag lokaal verkopen? Via Bolt kan je je stroom op een transparante manier aanbieden, krijg je een eerlijke injectievergoeding en maak je samen met ons het stroomnetwerk in België duurzaam.'
            )}
          />
          <Hero.CtaGroup>
            <Link representation="button" href={routes(router.locale).becomeProducerForm}>
              {t('hero.cta.request', 'Dien je aanvraag in')}
            </Link>
            <Button
              variant="secondary"
              onClick={() => {
                const scrollTo = document.getElementById('info-section')
                scrollTo?.scrollIntoView({ behavior: 'smooth' })
              }}
              trailingIcon="arrowBottom"
            >
              {t('hero.cta.knowMore', 'Meer weten')}
            </Button>
          </Hero.CtaGroup>
        </Hero>

        {/* INFO */}
        <SplitSection id="info-section" className={styles['info-section']}>
          <SplitSection.Left>
            <Heading as="h2" variant="h3">
              {t('info.durable.title', 'Samen voor een duurzaam stroomnetwerk')}
            </Heading>
            <p>
              {t(
                'info.durable.description',
                'Bij Bolt willen we niet wachten en een oplossing bieden voor de klimaatproblematiek. Door energie-afnemer en -opwekker te verbinden, brengen we transparantie en persoonlijkheid binnen een anonieme energiemarkt. We zorgen ervoor dat mensen weten waar hun stroom vandaan komt en waar hun geld naartoe gaat. Als energie-opwekker bij Bolt word je het gezicht achter duurzame stroom en maak je een duurzaam stroomnetwerk in België mogelijk.'
              )}
            </p>
            <Heading as="h2" variant="h3" className="mt-600">
              {t('info.personal.title', 'Een eerlijke en persoonlijke aanpak')}
            </Heading>
            <p>
              {t(
                'info.personal.description',
                'Officieel is Bolt een energieleverancier, maar we zien onszelf als een platform. We vormen de schakel tussen jou als energie-opwekker en onze energie-afnemers, een schakel tussen mensen. We vinden het enorm belangrijk om transparant en eerlijk advies rond energie te geven, en dit op een persoonlijke manier. We hebben dan ook een team dat dagelijks bezig is met het opvolgen van de contracten met onze energie-opwekkers. Hopelijk leer je hen binnenkort kennen?'
              )}
            </p>
          </SplitSection.Left>
          <SplitSection.Right className={styles['info-right']}>
            <picture className={styles['producers-image']}>
              <Img
                fill
                publicId={`website/producer-contact/${router.locale === Language.FRENCH ? 'producers-FR' : 'producers'}`}
                style={{ objectFit: 'contain', objectPosition: 'center right' }}
                alt="producers"
              />
            </picture>
          </SplitSection.Right>
        </SplitSection>

        {/* SLIDER */}
        <SliderSection slides={QUOTES} />

        {/* CONDITIONS SECTION */}
        <SplitSection className={styles['conditions-section']}>
          <SplitSection.Left className={styles.content}>
            <Heading as="h2" variant="h3">
              {t('conditions.title', 'Wat zijn de voorwaarden?')}
            </Heading>
            <p>
              {t(
                'conditions.description',
                'Om energie-opwekker bij Bolt te worden, moet je duurzame stroom produceren uit zon, wind, water of biogas. Dit met een professionele installatie waarbij het vermogen van de omvormer minstens 10 kVA is. Daarnaast heb je ook een ondernemingsnummer nodig.'
              )}
            </p>
            <Link representation="button" href={routes(router.locale).becomeProducerForm}>
              {t('hero.cta.request', 'Dien je aanvraag in')}
            </Link>

            <div className={styles['conditions-info']}>
              <Info />
              <p>
                {parse(
                  t(
                    'conditions.mini.info',
                    'Wil je als particulier een vergoeding voor je opgewekte stroom krijgen? Dit gebeurt automatisch bij je overstap naar Bolt.'
                  )
                )}
              </p>
            </div>
          </SplitSection.Left>
          <SplitSection.Right className={styles['conditions-right']}>
            <Img
              className={styles['conditions-image']}
              publicId={`website/producer-contact/${router.locale === Language.FRENCH ? 'willem-fr' : 'willem'}`}
              width={562}
              height={434}
              objectFit="contain"
              alt={t('conditions.alt')}
            />
          </SplitSection.Right>
        </SplitSection>

        {/* PRODUCERS */}
        <section className={classNames('section', styles['producers-section'])}>
          <div className="container">
            <div className={styles.intro}>
              <Heading as="h2" variant="h3">
                {t('producers.title', 'Maak deel uit van onze groeiende groep energie-opwekkers')}
              </Heading>
            </div>
          </div>

          <div className={classNames('container', styles['scrollable-wrapper'])}>
            <ul className={styles.producers}>
              {producers.map((producer) => (
                <li key={producer.id}>
                  <ProducerCard producer={producer} showCTA />
                </li>
              ))}
            </ul>
          </div>
        </section>

        {/* FAQ */}
        {!!questions.length && <FaqSection questions={questions} />}
      </main>
    </>
  )
}

export default ProducerContact
