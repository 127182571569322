import { SlideType } from 'components/slider-section/types'
import BlockquoteSlide from 'components/slider-section/blockquote-slide/BlockquoteSlide'

export const QUOTES: SlideType[] = [
  {
    id: 'stijn',
    img: 'website/producers/stijn',
    content: (
      <BlockquoteSlide
        namespace="generator"
        quote="slider.stijn.text"
        caption={{
          author: 'slider.stijn.author',
          extra: 'slider.extra'
        }}
      />
    )
  },
  {
    id: 'francis',
    img: 'website/producers/francis',
    content: (
      <BlockquoteSlide
        namespace="generator"
        quote="slider.francis.text"
        caption={{
          author: 'slider.francis.author',
          extra: 'slider.extra'
        }}
      />
    )
  },
  {
    id: 'miguel',
    img: 'website/producers/miguel',
    content: (
      <BlockquoteSlide
        namespace="generator"
        quote="slider.miguel.text"
        caption={{
          author: 'slider.miguel.author',
          extra: 'slider.extra'
        }}
      />
    )
  },
  {
    id: 'kim',
    img: 'website/producers/kim',
    content: (
      <BlockquoteSlide
        namespace="generator"
        quote="slider.kim.text"
        caption={{
          author: 'slider.kim.author',
          extra: 'slider.extra'
        }}
      />
    )
  }
]
