import Layout from 'components/layout/Layout'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { DEFAULT_NAMESPACES, PRODUCERS_NAMESPACES } from 'constants/i18n'
import ProducerContact from 'features/producer-contact/ProducerContact'
import { fetchProducers } from 'api/producer'
import { filterAndSortProducers } from 'utils/producers'
import { getBecomeProducerPage } from 'api/cms/become-producer'
import { Language } from 'types/language'
import type { ProducerContactProps } from 'features/producer-contact/types'
import type { SeoProps } from 'components/seo/types'

const ProducerContactPage = (props: ProducerContactProps) => {
  // constants
  const { seo } = props
  const SEO: SeoProps = seo && {
    description: seo.metaDescription,
    image: seo.seoImage,
    title: seo.seoTitle
  }

  return (
    <Layout seo={SEO}>
      <ProducerContact {...props} />
    </Layout>
  )
}

export const getStaticProps = async ({ locale }) => {
  const lang = locale !== 'default' ? locale : Language.DUTCH
  const [producers, cmsContent] = await Promise.all([
    await fetchProducers({ language: lang, params: { enabled: true, soldOut: false } }),
    await getBecomeProducerPage(lang)
  ])

  return {
    props: {
      producers: filterAndSortProducers({ hideSoldOut: true, language: locale, producers }).slice(0, 4),
      questions: cmsContent?.faq?.articles || [],
      seo: cmsContent?.seo,
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'generator', ...PRODUCERS_NAMESPACES]))
    },
    revalidate: 600 // re-generate page when a request comes in, once every 10 minutes
  }
}

export default ProducerContactPage
